/** ***************************************************************************
 * Templates / Home page
 *************************************************************************** */

:root {
  /**
   * Header rocks
   */

  --hns-c-page__header-rock--Color: var(--hns-c-page--Color);
}



/**
 * Header rocks
 */

body > header > .background > svg {
  color: var(--hns-c-page__header-rock--Color);
}



/**
 * Old code
 */

  /* typography */

    body > header {
      font-family: var(--hns-global--FontFamily-special);
      overflow: hidden;
    }

    body > header > :first-child {
      font-size: var(--hns-global--FontSize-title--2xl);
      font-weight: var(--hns-global--FontWeight-heavy);
    }

    body > header > .subtitle {
      font-size: var(--hns-global--FontSize-subtitle--sm);
      font-weight: var(--hns-global--FontWeight-bold);
      line-height: 1;
    }

    @media (min-width: 48rem) { /* md */
      body > header > :first-child {
        font-size: var(--hns-global--FontSize-title--4xl);
      }

      body > header > .subtitle {
        font-size: var(--hns-global--FontSize-subtitle--md);
      }
    }

  /* colors */

    body > header {
      color: var(--hns-global--palette-white);
    }

    body > header > svg { /* logo */
      --color-body: currentColor;
      --color-shadow: transparent;
      --color-valsabbia: currentColor;
    }

    .hns-c-tools .hns-c-social-links {
      --hns-c-social-links__icon--BackgroundColor: var(--hns-global--palette-blue--30);
    }

  /* layout */

    body > header {
      --column-content: 4 / -4;
      --height-logo: 6rem; /* 195px */
      --margin-logo-after: 2rem; /* 29px */
      --picture-offset: 8rem;
      --shape-picture: polygon(
        0 0,
        100% var(--picture-offset),
        100% 100%,
        0 100%
      );
      grid-template-rows:
        minmax(2rem, 1fr)
        [logo]
        var(--height-logo)
        var(--margin-logo-after)
        [heading]
        auto
        [subtitle]
        auto
        minmax(2rem, 1fr);
      min-height: 100vh;
    }

    body > header > * {
      grid-column: var(--column-content);
      grid-row-end: span 1;
      margin: 0;
      z-index: 0;
    }

    body > header > svg { /* logo */
      grid-row-start: logo;
      height: 100%;
    }

    body > header > :first-child { /* title */
      grid-row-start: heading;
      margin-bottom: 2.25rem; /* 37px */
    }

    body > header > .subtitle {
      grid-row-start: subtitle;
    }

    body > header > .background {
      --gradient-background: linear-gradient(
        244deg,
        rgba(13,69,128,0.00) 13%,
        rgba(13,69,128,0.71) 56%
      );
      grid-column: 1 / -2;
      grid-row: 1 / -1;
      position: relative;
      z-index: -1;
    }

    body > header > .background > * {
      position: absolute;
      z-index: 0;
    }

    body > header > .background > svg {
      display: none;
      height: calc(var(--width-rock) * var(--ratio-height-rock));
      position: absolute;
      transform-origin: center;
      width: var(--width-rock);
      z-index: -1;
    }

    body > header .background > svg:nth-of-type(1) {
      --width-rock: 2rem; /* 32px */
    }

    body > header .background > svg:nth-of-type(2) {
      --width-rock: 3.56rem; /* 57px */
    }

    body > header .background > svg:nth-of-type(3) {
      --width-rock: 5.56rem; /* 89px */
    }

    body > header .background > svg:nth-of-type(4) {
      --width-rock: 7.5rem; /* 122px */
    }

    body > header .background > svg:nth-of-type(5) {
      --width-rock: 7rem; /* 111px */
    }

    body > header .background > svg:nth-of-type(6) {
      --width-rock: 7rem; /* 111px */
    }

    body > header .background > svg:nth-of-type(7) {
      --width-rock: 12.5rem; /* 201px */
    }

    body > header > .background > div::after {
      background-image: var(--gradient-background);
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      mix-blend-mode: multiply;
    }

    body > header > .background > div {
      clip-path: var(--shape-picture);
    }

    body > header > .background > div,
    body > header > .background > div > img {
      height: 100%;
      left: 0;
      top: 0;
      width: 100%;
    }

    body > header > .background img {
      display: block;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }

    @media (min-width: 34rem) { /* sm */

    }

    @media (min-width: 48rem) { /* md */
      body > header {
        --column-content: 7 / -9;
        --height-logo: 12rem; /* 195px */
        --margin-logo-after: 2rem; /* 29px */
        --picture-offset: 10rem;
      }

      body > header .background svg:nth-of-type(-n+3) {
        display: block;
        left: 52%;
        top: -.5%;
      }

      body > header .background > svg:nth-of-type(1) {
        transform: translate(0, 0) rotateZ(-224deg);
      }

      body > header .background > svg:nth-of-type(2) {
        transform: translate(120%, 13%) rotateZ(-224deg);
      }

      body > header .background > svg:nth-of-type(3) {
        transform: translate(0, 100%) rotateZ(-153deg);
      }
    }

    @media (min-width: 64rem) { /* lg */
      body > header {
        --picture-offset: calc(2 * var(--hns-global--content-grid--column-width) + 2 * var(--hns-global--content-grid--gutter-width));
        --shape-picture: polygon(
          0 0,
          calc(100% - var(--picture-offset)) 0,
          calc(100% - var(--picture-offset) - 2 * var(--hns-global--content-grid--column-width) - var(--hns-global--content-grid--gutter-width)) 100%,
          0 100%
        );
      }

      body > header .background svg:nth-of-type(n+4):nth-of-type(-n+5) {
        bottom: 2.5rem;
        display: block;
        left: calc(50vw + 4 * var(--hns-global--content-grid--column-width) + (2 / 3 + 3) * var(--hns-global--content-grid--gutter-width));
      }

      body > header .background > svg:nth-of-type(4) {
        transform: translate(41%, -153%) rotateZ(-159deg)
      }

      body > header .background > svg:nth-of-type(5) {
        transform: rotateZ(-15deg);
      }
    }

    @media (min-width: 90rem) { /* hg */
      body > header .background svg:nth-last-of-type(-n+2) {
        display: block;
        left: calc(50vw + 7 * var(--hns-global--content-grid--column-width) + (2 / 3 + 6) * var(--hns-global--content-grid--gutter-width));
        top: 61%;
      }

      body > header .background > svg:nth-of-type(6) {
        transform: rotateZ(-15deg);
      }

      body > header .background > svg:nth-of-type(7) {
        transform: translate(37%, 37%) rotateZ(-225deg);
      }
    }

    /* I *REALLY* hate Edge */

      @supports (-ms-ime-align:auto) { /* edge 12+ */
        body > header .background > div {
          --angle-cut: 205deg;
          --position-cut: 42vw;
          mask-image: linear-gradient(
            var(--angle-cut),
            transparent 0,
            transparent var(--position-cut),
            black 0,
            black 100%
          );
        }

        @media (min-width: 34rem) { /* sm */
          body > header .background > div {
            --angle-cut: 192deg;
            --position-cut: 21vw;
          }
        }

        @media (min-width: 64rem) { /* lg */
          body > header .background > div {
            --angle-cut: 275deg;
            --position-cut: 21vw;
          }
        }

      }

  /* special effects */

    body > header .background > div {
      transition: clip-path var(--hns-global--Duration--sm) linear;
    }

  /* vertical spacings */

  /* vertical spacings */

    main {
      padding-top: 2.25rem; /* 37px */
    }

    @media (min-width: 48rem) { /* md */
      main {
        padding-top: 3.5rem; /* 55px */
      }
    }


/**
 * Let's move some stuff
 */

:root {
  --hns-c-page__content--AnimationDuration: 1s;
  --hns-c-page__content--AnimationTimingFunction: var(--hns-global--AnimationTimingFunction--easeoutcubic);

  --hns-c-page__image--AnimationDelay: 0s;
  --hns-c-page__image--AnimationDuration: 1s;
  --hns-c-page__image--AnimationTimingFunction: var(--hns-global--AnimationTimingFunction--easeoutcubic);

  --hns-c-page__rock--AnimationDuration: 1s;
  --hns-c-page__rock--AnimationDelay: calc(
    var(--hns-c-page__image--AnimationDelay)
    + var(--hns-c-page__image--AnimationDuration) /* image duration */
    - 200ms
  );
  --hns-c-page__rock--AnimationTimingFunction: var(--hns-global--AnimationTimingFunction--easeoutcubic);
}


/* rocks */
body > header .background > svg {
  --animation-delay: calc(
    var(--hns-c-page__rock--AnimationDelay)
    + var(--staggering-order) * var(--hns-global--Duration--xs)
  );
  animation-name: hns-global--keyframes-hide, hns-global--keyframes-fadein;
  animation-timing-function: linear, var(--hns-global--AnimationTimingFunction--easeoutcubic);
  animation-delay: 0ms, var(--animation-delay);
  animation-duration: var(--animation-delay), var(--hns-c-page__rock--AnimationDuration);
  will-change: opacity;
}

  body > header .background > svg:nth-of-type(1) {
    --staggering-order: 0;
  }

  body > header .background > svg:nth-of-type(2) {
    --staggering-order: 1;
  }

  body > header .background > svg:nth-of-type(3) {
    --staggering-order: 2;
  }

  body > header .background > svg:nth-of-type(4) {
    --staggering-order: 3;
  }

  body > header .background > svg:nth-of-type(5) {
    --staggering-order: 4;
  }

  body > header .background > svg:nth-of-type(6) {
    --staggering-order: 5;
  }

  body > header .background > svg:nth-of-type(7) {
    --staggering-order: 6;
  }

/* image */
body > header .background > div {
  --animation-delay: var(--hns-c-page__image--AnimationDelay);

  animation-delay: 0ms, var(--animation-delay);
  animation-duration: var(--animation-delay), var(--hns-c-page__image--AnimationDuration);
  animation-name: hns-global--keyframes-hide, hns-global--keyframes-fadein;
  animation-timing-function: linear, var(--hns-c-page__image--AnimationTimingFunction);
  will-change: opacity;
}

/* header contents */
body > header > svg,
h1,
body > header .breadcrumb,
body > header .subtitle {
  --animation-delay: calc(var(--staggering-order) * var(--hns-global--Duration--tn));
  --animation-duration: var(--hns-c-page__content--AnimationDuration);

  animation-delay: 0ms, var(--animation-delay);
  animation-duration: var(--animation-delay), var(--animation-duration);
  animation-name: keepdown, slideup;
  animation-timing-function: linear, var(--hns-global--AnimationTimingFunction--easeoutcubic);
}

  /* logo */
  body > header > svg {
    --staggering-order: 0;
  }

  /* title */
  h1 {
    --staggering-order: 1;
  }

  /* breadcrumb */
  body > header .breadcrumb {
    --staggering-order: 2;
  }

  /* subtitle */
  body > header .subtitle {
    --staggering-order: 3;
  }


@keyframes slideup {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
}

  @keyframes keepdown {
    from {
      opacity: 0;
      transform: translateY(100px);
    }
    to {
      opacity: 0;
      transform: translateY(100px);
    }
  }
